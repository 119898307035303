
import { computed, defineComponent, toRefs } from "vue"
import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue"
import { FormTypes } from "@/core/enums/form-types"
import NewCountryForm from "../forms/NewCountryForm.vue"
import { hideModalById } from "@/core/helpers/dom"

export default defineComponent({
    components:{
        DynamicContentModal
        , NewCountryForm
    }
    , props: {
        formType: {//indica como se comportara el formulario
            type: Number
            , default: FormTypes.Create
        }
    }
    , setup(props){
        const { formType } = toRefs(props);

        const closeModal = () => {
            hideModalById("#new-country-form");
        };

        const modalTitle = computed(() => {
            return formType.value == 1 ? "Actualizar País" : formType.value == 2 ? "Nuevo País" : formType.value == 3 ? "Visualizar País" : "";
        });

        return {
            FormTypes
            , closeModal
            , modalTitle
        }
    }
})
